import { Injectable } from '@angular/core';
import { BaseServicePadrao } from '../core/base-service-padrao';
import { Http } from '@angular/http';
import { timeout, map, catchError } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class ColetasService extends BaseServicePadrao {
	urlBase: string;
	constructor(private http: Http) {
		super(http, 'coletas');
	}

	getByParam(param: string, value: string | number) {
		this.checkToken();
		return this.http.get(this.urlBase + '/getByParam/' + param + '/' + value, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	consultarStatus_Dashboard(inicio: number, fim: number) {
		this.checkToken();
		return this.http.get(this.urlBase + '/consultarStatus_Dashboard/' + inicio + '/' + fim, this.options).pipe(
			timeout(30000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	consultarIntervaloColetas(posto: string, inicio: number, fim: number, filtro?: string) {
		this.checkToken();
		return this.http.get(this.urlBase + '/intervalo/' + posto + '/' + inicio + '/' + fim + '/' + filtro, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	getBadgeCountView() {
		this.checkToken();
		return this.http.post(this.urlBase + '/getBadgeCountView', this.options).pipe(
			timeout(30000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	getColetasViewProcesso(conteudo: any) {
		this.checkToken();
		return this.http.post(this.urlBase + '/getColetasViewProcesso', conteudo, this.options).pipe(
			timeout(60000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	getColetasViewCanceladas(conteudo: any) {
		this.checkToken();
		return this.http.post(this.urlBase + '/getColetasViewCanceladas', conteudo, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	consultarIntervaloNotas(inicio: number, fim: number, filtro?: string) {
		this.checkToken();
		if (filtro) {
			return this.http.get(this.urlBase + '/intervaloNotas/' + inicio + '/' + fim + '/' + filtro, this.options).pipe(
				timeout(40000),
				map((dados) => this.wsHandler.handle(dados)),
				catchError(this.handleError),
			);
		} else {
			return this.http.get(this.urlBase + '/intervaloNotas/' + inicio + '/' + fim, this.options).pipe(
				timeout(40000),
				map((dados) => this.wsHandler.handle(dados)),
				catchError(this.handleError),
			);
		}
	}

	consultarIntervaloColetasEnvio(posto: string, inicio: number, fim: number) {
		this.checkToken();
		return this.http.get(this.urlBase + '/intervaloEnvio/' + posto + '/' + inicio + '/' + fim, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	consultarIntervaloAprovacao(inicio: number, fim: number, filtro?: string) {
		this.checkToken();
		if (filtro) {
			return this.http.get(this.urlBase + '/intervaloAprovacao/' + inicio + '/' + fim + '/' + filtro, this.options).pipe(
				timeout(40000),
				map((dados) => this.wsHandler.handle(dados)),
				catchError(this.handleError),
			);
		} else {
			return this.http.get(this.urlBase + '/intervaloAprovacao/' + inicio + '/' + fim, this.options).pipe(
				timeout(40000),
				map((dados) => this.wsHandler.handle(dados)),
				catchError(this.handleError),
			);
		}
	}

	consultarIntervaloLiberacao(inicio: number, fim: number, filtro?: string) {
		this.checkToken();
		if (filtro) {
			return this.http.get(this.urlBase + '/intervaloLiberacao/' + inicio + '/' + fim+ '/' + filtro, this.options).pipe(
				timeout(40000),
				map((dados) => this.wsHandler.handle(dados)),
				catchError(this.handleError),
			);
		} else {
			return this.http.get(this.urlBase + '/intervaloLiberacao/' + inicio + '/' + fim, this.options).pipe(
				timeout(40000),
				map((dados) => this.wsHandler.handle(dados)),
				catchError(this.handleError),
			);
		}
	}

	editar(conteudo: any) {
		this.checkToken();
		return this.http.post(this.urlBase + '/atualizar', conteudo, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	descancelar(conteudo: any) {
		this.checkToken();
		return this.http.post(this.urlBase + '/descancelarAmostra/', conteudo, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	estornar(conteudo: any) {
		this.checkToken();
		return this.http.post(this.urlBase + '/estornarAmostra/', conteudo, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	getMotivosCancel() {
		this.checkToken();
		return this.http.get(this.urlBase + '/getMotivoCancel/', this.options).pipe(
			timeout(20000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}

	cancelar(conteudo: any) {
		this.checkToken();
		return this.http.post(this.urlBase + '/cancelarAmostra', conteudo, this.options).pipe(
			timeout(40000),
			map((dados) => this.wsHandler.handle(dados)),
			catchError(this.handleError),
		);
	}
}
